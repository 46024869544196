<template>
  <a-modal
    :title="form.id ? '编辑任务' : '新建任务'"
    :maskClosable="false"
    v-model="showUpdateModal"
    width="700px"
    centered
    v-bind="$attrs"
    v-on="$listeners"
    @close="handleModalClose"
    @cancel="handleModalClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :rules="rules">
      <div class="form-title">任务规则</div>
      <a-form-model-item label="任务名称" prop="taskName">
        <a-input v-model.trim="form.taskName" placeholder="任务名称" :maxLength="20"></a-input>
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="taskType">
        <a-select v-model="form.taskType" placeholder="任务类型">
          <a-select-option value="PHOTOS">拍摄图片</a-select-option>
          <a-select-option value="VIDEOS">拍摄视频</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拍摄数量" prop="num">
        <a-input
          type="number"
          :min="1"
          :max="100"
          v-model.trim="form.num"
          placeholder="请填写拍摄数量"
          @blur="handleNumBlur"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-textarea
          v-model="form.description"
          placeholder="请输入任务说明，如拍摄5张车身图"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxLength="200"
        ></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="有效时间" prop="range">
        <a-range-picker
          v-model="form.range"
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        />
      </a-form-model-item>
      <div class="form-title">任务成员</div>
      <a-form-model-item label="品牌" prop="principalId">
        <BrandSelectInput
          placeholder="品牌"
          v-model="form.principalId"
          show-search
          option-filter-prop="children"
          allowClear
          @change="handleBrandChange"
        />
      </a-form-model-item>
      <a-form-model-item label="车系" prop="carSeriesId">
        <CarSeriesSelectInput
          :principalIds="formPrincipalIds"
          v-model="form.carSeriesId"
          placeholder="车系"
          show-search
          option-filter-prop="children"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item label="任务参与成员" prop="memberIdList">
        <a-select v-model="form.memberIdList" placeholder="选择成员" mode="multiple">
          <a-select-option v-for="v in memberList" :key="v.memberId">{{ v.memberName }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="form-title">参考图片</div>
      <a-form-model-item label="图片列表" prop="imgList">
        <a-upload
          name="file"
          list-type="picture-card"
          accept="'.png,.jpg,.jpeg,"
          :customRequest="customRequest"
          :beforeUpload="beforeUploadImg"
          :file-list="file_list"
          @preview="handlePreview"
          @change="handleChange"
        >
          <div v-if="file_list.length < 10">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <div style="color: #d7d7d7; user-select: none">建议上传，参考图片会同步至经销商端查看</div>
      </a-form-model-item>
    </a-form-model>
    <div slot="footer">
      <a-button @click="handleModalClose">取消</a-button>
      <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import SparkMD5 from 'spark-md5';
import { rules } from '../data';
import { api as viewerApi } from 'v-viewer';
import api from '@/api/xhsAgencyApi';

export default {
  name: '',
  props: {},
  components: { BrandSelectInput, CarSeriesSelectInput },
  mixins: [ossUploadMixin],

  data() {
    return {
      rules,
      showUpdateModal: false,
      memberList: [],
      form: {
        id: undefined,
        taskType: 'PHOTOS',
        num: undefined,
        principalId: undefined,
        carSeriesId: undefined,
        memberIdList: undefined,
      },
      file_list: [],
      confirmLoading: false,
    };
  },
  computed: {
    formPrincipalIds() {
      return this.form.principalId?.length > 0 ? [this.form.principalId.split(',')[0]] : [];
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleOpen(id) {
      this.showUpdateModal = true;
      id && this.getTaskDetail(id);
    },
    async getTaskDetail(id) {
      const { code, data, message } = await api.getTaskDetail(id);
      if (code === 200) {
        this.form = {
          id: data.id,
          num: data.num,
          principalId: `${data.principalId},${data.principalName}`,
          carSeriesId: `${data.carSeriesId},${data.carSeriesName}`,
          description: data.description,
          memberIdList: data.memberIdList || undefined,
          taskName: data.taskName,
          taskType: data.taskType,
          range:
            data.startTime && data.endTime ? [data.startTime.split(' ')[0], data.endTime.split(' ')[0]] : undefined,
        };
        this.getMemberList(data.principalId);
        if (data.referMaterials && data.referMaterials.length) {
          this.file_list = data.referMaterials.map((item) => {
            const strId = Math.random() + '';
            return {
              uid: strId,
              name: strId,
              status: 'done',
              url: item,
            };
          });
        } else {
          this.file_list = [];
        }
      } else {
        this.$message.error(message);
      }
    },
    handleBrandChange(val) {
      this.memberList = [];
      this.form.memberIdList = undefined;
      this.form.carSeriesId = undefined;

      if (val) {
        const principalId = this.form.principalId.split(',')[0];
        this.getMemberList(principalId);
      }
    },
    async getMemberList(principalId) {
      const params = {
        principalId,
        status: 'NORMAL',
        page: 1,
        size: 999,
      };
      const { code, data, message } = await api.getMemberList(params);
      if (code == 200) {
        this.memberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handleConfirm() {
      const hasUnDone = this.file_list.some((v) => v.status === 'uploading');
      if (hasUnDone) {
        this.$message.warning('请等待全部图片上传完成');
        return;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { id, num, principalId, carSeriesId, description, memberIdList, range, taskName, taskType } = this.form;
          const referMaterials = this.file_list.filter((v) => v.status === 'done').map((v) => v.url);
          const params = {
            id,
            num: Number(num),
            description,
            memberIdList,
            taskName,
            taskType,
            referMaterials,
            startTime: range && range.length ? `${range[0]} 00:00:00` : undefined,
            endTime: range && range.length ? `${range[1]} 23:59:59` : undefined,
            principalId: principalId ? principalId.split(',')[0] : undefined,
            principalName: principalId ? principalId.split(',')[1] : undefined,
            carSeriesId: carSeriesId ? carSeriesId.split(',')[0] : undefined,
            carSeriesName: carSeriesId ? carSeriesId.split(',')[1] : undefined,
          };
          console.log('params ->', params);
          const APINAME = params.id ? 'handleUpdateTask' : 'handleAddTask';
          this.confirmLoading = true;
          const { code, message } = await api[APINAME](params).finally(() => (this.confirmLoading = false));
          if (code == 200) {
            this.$message.success(message);
            this.handleModalClose();
            this.$emit('refresh');
          } else {
            this.$message.error(message);
          }
        } else {
          return false;
        }
      });
    },
    handleModalClose() {
      this.$refs.form.clearValidate();
      this.form = this.$options.data().form;
      this.file_list = [];
      this.memberList = [];
      this.showUpdateModal = false;
    },
    // -------------------------------------------------------图片上传
    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.normalUploadFile(file, onSuccess, true);
    },
    handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;

        if (info.file.status === 'uploading') {
          this.file_list.forEach((val) => {
            if (val.fileId && val.fileId === info.file.fileId) {
              console.log('val.fileId ->', val.fileId, info.file.fileId);
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.response.data.data.id;
              file.url = file.response.data.data.fileUrl;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
        }

        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        return;
      }
    },
    handlePreview(v) {
      const images = this.file_list.filter((v) => v.status === 'done');
      if (images.length) {
        const index = images.findIndex((val) => val.uid === v.uid);
        if (index == -1) return;
        const $viewer = this.$viewerApi({
          options: {
            toolbar: true,
            initialViewIndex: index,
          },
          images: images.map((val) => val.url),
        });
      }
    },
    beforeUploadImg(file, fileList) {
      const currentImgLength = fileList.length + this.file_list.length;
      if (currentImgLength > 10) {
        this.$message.info('最多上传10张图片');
        return false;
      }
      return new Promise((resolve, reject) => {
        const isLimit = file.size / 1024 / 1024 < 20;
        if (!isLimit) {
          this.$message.error(`上传文件不超过20MB!`);
          return false;
        }
        let fileType = /\.(jpeg|jpg|png)$/i;
        if (!fileType.test(file.name)) {
          this.$message.error('仅支持图片文件');
          return false;
        }
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath: '/bfz/xhs/',
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
          };
          return resolve(true);
        };
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
